import React from 'react'
import Area from 'site/Renderer/components/Area/Area'
import TopLine from 'site/Renderer/components/TopLine'
import FilterFields from 'site/Renderer/components/FilterFields/FilterFields'
import DataTables from 'site/Renderer/components/DataTables/DataTables'
import ObjectView from 'site/Renderer/components/ObjectView/ObjectView'
import DataCards from 'site/Renderer/components/DataCards/DataCards'
import SupportBannerSecondary from './SupportBannerSecondaryContainer'
import NavigationSliderSecondary from 'site/Renderer/components/NavigationSliderSecondaryContainer/NavigationSliderSecondaryContainer'
import HeroBanner from 'site/Renderer/components/HeroBannerContainer/HeroBannerContainer'
import DynamicTextHighlight from 'site/Renderer/components/DynamicTextHighlightContainer/DynamicTextHighlightContainer'
import SiteFooter from 'site/Renderer/components/SiteFooterContainer/SiteFooterContainer'
import Article from 'site/Renderer/components/ArticleContainer/ArticleContainer'
import DynamicInfoCardHighlight from 'site/Renderer/components/DynamicInfoCardHighlightContainer/DynamicInfoCardHighlightContainer'
import SupportBanner from 'site/Renderer/components/SupportBannerContainer'
import HeroBannerSecondary from 'site/Renderer/components/HeroBannerSecondaryContainer/HeroBannerSecondaryContainer'
import Cards from 'site/Renderer/components/CardContainer/CardContainer'
import Workflowmodel from 'site/Renderer/components/WorkflowmodelContainer/WorkflowmodelContainer'
import Kanban from 'site/Renderer/components/Kanban'
import TextExpression from './TextExpressionContainer/TextExpressionContainer'
import Cockpit from 'site/Renderer/components/Cockpit/Cockpit'
import Authentication from 'site/Renderer/components/Authentication'
import DataMap from 'site/Renderer/components/MapContainer/MapContainer'
import Unauthorized from './UnauthorizedContainer/UnauthorizedContainer'
import CardCarousel from 'site/Renderer/components/CardCarousel'
import SummaryDetailsCarousel from './SummaryDetailsCarouselContainer/SummaryDetailsCarouselContainer'
import PartnerCard from './PartnerCardContainer/PartnerCardContainer'
import EventCard from './EventCardContainer/EventCardContainer'
import ArticlesList from './ArticlesListContainer/ArticlesListContainer'
import TabsContent from './TabsContent/TabsContent'
import Accordion from './Accordion'

import { ComponentTypeGuard } from '../wrappers/TypeGuard/TypeGuard.types'
import { SupportBannerContainerTypeGuard } from './SupportBannerContainer/SupportBannerContainer.types'
import { HeroBannerSecondaryContainerTypeGuard } from './HeroBannerSecondaryContainer/HeroBannerSecondaryContainer.types'
import { NavigationSliderSecondaryContainerTypeGuard } from './NavigationSliderSecondaryContainer/NavigationSliderSecondaryContainer.types'
import { DynamicTextHighlightContainerTypeGuard } from './DynamicTextHighlightContainer/DynamicTextHighlightContainer.types'
import { ArticleContainerTypeGuard } from './ArticleContainer/ArticleContainer.types'
import { SupportBannerSecondaryContainerTypeGuard } from './SupportBannerSecondaryContainer/SupportBannerSecondaryContainer.types'
import { CardContainerTypeGuard } from './CardContainer/CardContainer.types'
import { WorkflowmodelContainerTypeGuard } from './WorkflowmodelContainer/WorkflowmodelContainer.types'
import { FilterFieldsTypeGuard } from './FilterFields/types/guard'
import { DataCardsTypeGuard } from './DataCards/DataCards.types'
import { TopLineTypeGuard } from './TopLine/TopLine.types'
import { DataTablesTypeGuard } from './DataTables/types/guard'
import { KanbanTypeGuard } from './Kanban/Kanban.types'
import { TextExpressionContainerTypeGuard } from './TextExpressionContainer/TextExpressionContainer.types'
import { AuthenticationTypeGuard } from './Authentication/Authentication.types'
import { AccordionTypeGuard } from './Accordion/Accordion.types'
import { TabsContentTypeGuard } from './TabsContent/TabsContent.types'
import { HeroBannerContainerTypeGuard } from './HeroBannerContainer/HeroBannerContainer.types'
import { Col, Row } from './Grid/Grid'

export type ComponentsPathsOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: React.FC<{ props: any }>
}

export type ComponentsTypeGuard = {
  [key: string]: ComponentTypeGuard
}

export const componentsPaths: ComponentsPathsOptions = {
  SupportArea: Area,
  FilterArea: Area,
  DataArea: Area,
  ObjectArea: Area,
  TopLine,
  FilterFields,
  DataTables,
  ObjectView,
  DataCards,
  SupportBannerSecondary,
  DynamicTextHighlight,
  SiteFooter,
  HeroBanner,
  Article,
  NavigationSliderSecondary,
  DynamicInfoCardHighlight,
  SupportBanner,
  HeroBannerSecondary,
  Cards,
  Workflowmodel,
  Kanban,
  TextExpression,
  DataMap,
  Cockpit,
  Authentication,
  Unauthorized,
  CardCarousel,
  SummaryDetailsCarousel,
  PartnerCard,
  Accordion,
  EventCard,
  ArticlesList,
  TabsContent,
  Row,
  Col
}

export const componentsTypeGuard: ComponentsTypeGuard = {
  HeroBanner: HeroBannerContainerTypeGuard,
  HeroBannerSecondary: HeroBannerSecondaryContainerTypeGuard,
  NavigationSliderSecondary: NavigationSliderSecondaryContainerTypeGuard,
  DynamicTextHighlight: DynamicTextHighlightContainerTypeGuard,
  Article: ArticleContainerTypeGuard,
  SupportBanner: SupportBannerContainerTypeGuard,
  SupportBannerSecondary: SupportBannerSecondaryContainerTypeGuard,
  Card: CardContainerTypeGuard,
  Workflowmodel: WorkflowmodelContainerTypeGuard,
  FilterFields: FilterFieldsTypeGuard,
  DataCards: DataCardsTypeGuard,
  TopLine: TopLineTypeGuard,
  DataTables: DataTablesTypeGuard,
  Kanban: KanbanTypeGuard,
  TextExpression: TextExpressionContainerTypeGuard,
  Authentication: AuthenticationTypeGuard,
  Accordion: AccordionTypeGuard,
  TabsContent: TabsContentTypeGuard
}
