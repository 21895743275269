import {
  Row as ToolkitRow,
  Col as ToolkitCol,
  BreakpointStart
} from '@yes.technology/react-toolkit'

export type RowProps = {
  props: {
    children: React.ReactNode
  }
}

export const Row = ({ props: { children } }: RowProps) => (
  <ToolkitRow {...{ children }} />
)

type Breakpoint = keyof typeof BreakpointStart

export type ColProps = {
  props: {
    children: React.ReactNode
  } & Record<Breakpoint, number | 'auto'> & {
      offset: Record<Breakpoint, number>
    }
}

export const Col = ({
  props: { xs, sm, md, lg, xl, offset, children }
}: ColProps) => <ToolkitCol {...{ xs, sm, md, lg, xl, offset, children }} />
